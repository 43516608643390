import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';

import usePaperDownloadsSummary from 'hooks/papers/usePaperDownloadsSummary';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import CustomInfiniteLoadTable from 'components/elements/CustomInfiniteLoadTable';
import AlertCard from 'components/elements/AlertCard';
import SummaryRow from './summary-row';


const SummaryList = ({
                         height, refresh = 0, selectedVertical, sessionId, sort,
                     }) => {
    const {t} = useTranslation();

    const [flattenedResults, setFlattenedResults] = React.useState([]);

    const {
        data,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isError,
        status,
        refetch,
    } = usePaperDownloadsSummary({
        sessionId,
        sort,
        vertical: selectedVertical,
        enabled: selectedVertical !== ''
    });

    useEffect(() => {
        if (data) {
            setFlattenedResults(data.pages.reduce((accum, curr) => {
                return [...accum, ...(curr.data ?? [])];
            }, []))
        }
    }, [data])

    useEffect(() => {
        refetch()
    }, [refresh])

    const columns = useMemo(
        () => [
            {
                Header: t('inbox.paperName'),
                accessor: 'paper_name',
                align: 'left',
                width: '80%',
                sortable: false,
                sticky: false,
            },
            {
                Header: t('Download Status'),
                accessor: 'download_status',
                align: 'left',
                width: '20%',
                sortable: true,
                sticky: false,
            },
        ],
        [t]
    );

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 120}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'success' && flattenedResults && flattenedResults.length === 0) {
        return <Grid item xs={12} container spacing={1}>
            <AlertCard severity={'info'} message={'No data available'} height={height}/>
        </Grid>
    }

    return <Grid container>
        <Grid item xs={12}>
            {flattenedResults && <CustomInfiniteLoadTable
                height={height}
                data={flattenedResults}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isError={isError}
                columns={columns}
                sort={sort}
                refetch={refetch}
                minWidth={'auto'}
                itemTemplate={({data, index}) => <SummaryRow data={data} index={index} refetch={refetch}/>}
            />}
        </Grid>
    </Grid>
};

export default SummaryList;
