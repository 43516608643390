import React, {lazy, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {createSearchParams, useNavigate} from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DownloadingIcon from '@mui/icons-material/Downloading';
import {format, parseISO} from 'date-fns';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import IconButton from '@mui/material/IconButton';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import LabeledChip from 'components/elements/LabeledChip';
import CustomButton from 'components/elements/CustomButton';
import CustomTooltip from 'components/elements/CustomTooltip';
import {ROUTE_INBOX_EXPLORE} from 'pages/constants';
import ExecutedUser from 'components/elements/ExecutedUser';

import ViewPaperDrawer from './viewPaperDrawer';

const SearchSessionCardDetails = lazy(() => import('./search-session-card-details'));

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 6px 0px',
}));

const Accordion = styled(MuiAccordion)(({theme}) => ({
    borderWidth: '0.1em',
    borderStyle: 'solid',
    borderColor: '#E9E9E9',
    boxShadow: 'none',
    backgroundColor: 'transparent',
}));

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({theme}) => ({
    fontSize: 'small',
    color: '#696969'
}));

const SearchSessionCard = ({style, data, index, scroll}) => {
        const {t} = useTranslation();
        const [hoverRef] = useHover();
        const navigate = useNavigate();
        const [paperViewing, setPaperViewing] = useState(false);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const paperViewingOpen = Boolean(paperViewing);

        const handleView = (event) => {
            event.stopPropagation();
            setPaperViewing(data)
        }

        const handleViewPaperClose = () => {
            setPaperViewing(null);
            setAnchorEl(null);
        }

        const statusIcon = (status) => {
            switch (status) {
                case 'COMPLETED':
                    return <CheckCircleIcon sx={{color: '#00c360'}}/>;
                case 'FAILED':
                    return <CancelIcon sx={{color: '#ff0000'}}/>;
                case 'QUEUED':
                    return <PendingOutlinedIcon sx={{color: '#EED437'}}/>;
                case 'PARTIALLY COMPLETED':
                    return <AutoModeIcon fontSize={"small"} sx={{color: '#EED437'}}/>;
                case 'RUNNING':
                    return <DownloadingIcon sx={{color: '#0894d3'}}/>;
                default:
                    return <></>;
            }
        };

        const getStatus = ({sources = []}) => {
            let status = 'QUEUED'
            let successCount = 0;
            let errorCount = 0;
            let pendingCount = 0;
            let startedCount = 0;
            let downloadingCount = 0;

            sources.forEach(source => {
                switch (source.status) {
                    case 'COMPLETED':
                        successCount++;
                        break;
                    case 'FAILED':
                        errorCount++;
                        break;
                    case 'QUEUED':
                        pendingCount++;
                        break;
                    case 'PARTIALLY COMPLETED':
                        startedCount++;
                        break;
                    case 'RUNNING':
                        downloadingCount++;
                        break;
                    default:
                        break;
                }
            });

            if (errorCount > 0) {
                status = 'FAILED'
            } else if (pendingCount > 0) {
                status = 'QUEUED'
            } else if (downloadingCount > 0) {
                status = 'RUNNING'
            } else if (startedCount > 0) {
                status = 'PARTIALLY COMPLETED'
            } else if (successCount === sources.length) {
                status = 'COMPLETED'
            }
            return status
        };

        const statusTooltip = (status) => {
            switch (status) {
                case 'COMPLETED':
                    return t('Completed');
                case 'FAILED':
                    return t('Failed');
                case 'QUEUED':
                    return t('Queued');
                case 'PARTIALLY COMPLETED':
                    return t('Partially Completed');
                case 'RUNNING':
                    return t('Downloading');
                default:
                    return '';
            }
        };

        const getSource = (source) => {
            switch (source) {
                case 'SEMANTIC_SCHOLAR':
                    return 'Semantic scholar';
                case 'GOOGLE_SCHOLAR':
                    return 'Google scholar';
                case 'MANUAL':
                    return 'Manual';
                default:
                    return '';
            }
        }

        const getColor = (source) => {
            switch (source) {
                case 'SEMANTIC_SCHOLAR':
                    return colors[0];
                case 'GOOGLE_SCHOLAR':
                    return colors[1];
                case 'MANUAL':
                    return colors[2];
                default:
                    return '';
            }
        }

        const handleViewPapers = (value) => {
            navigate({
                pathname: ROUTE_INBOX_EXPLORE,
                search: `?${createSearchParams({'download_session': value})}`
            });
        }

        const colors = ['#efefcb', '#c8edfd', '#daf7ca']

        return (
            <CardRoot style={style} ref={hoverRef}>
                <Accordion TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        role="div"
                        sx={{
                            '&.MuiAccordionSummary-root': {
                                boxShadow: '1px 2px 13px -10px #888',
                                backgroundColor: '#fff',
                                cursor: 'default !important'
                            },
                            '.MuiExpansionPanelSummary-root:hover': {
                                cursor: "default !important"
                            },
                            '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                            div: {
                                '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                            },
                        }}

                    >
                        <Grid container alignItems="flex-start" justifyContent="space-between" flexWrap='nowrap' spacing={2}
                        >
                            <Grid container item xs={0.4} alignItems='center' justifyContent='center' sx={{height: '100%'}}>
                                <Grid item>
                                    <CustomTooltip title={statusTooltip(getStatus({sources: data['source_providers']}))}>
                                        {statusIcon(getStatus({sources: data['source_providers']}))}
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={0.2} sx={{height: '100%'}}>
                                <Divider sx={{
                                    '&.MuiDivider-root': {
                                        borderWidth: '1px',
                                        borderColor: '#a7a7a7',
                                        width: 'fit-content'
                                    }
                                }}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={5}
                                headerName={t('Session name')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>
                                        {/*{data['download_session_name']}*/}
                                        {`${data['search_phrase']['search_phrase']}-${format(parseISO(data.created_at), 'yyyy-MM-dd HH:mm:ss')}`}
                                        <span style={{paddingLeft: '0.5em', verticalAlign: '-0.1em'}}>
                                            <CustomTooltip title={<span>
                                                <b>Search phrase:</b> {data['search_phrase']['search_phrase']}<br/>
                                                {data['session_metrics'] && <span>
                                                <b>No of papers fetched:</b> {data['session_metrics']['no_of_search_results']}<br/>
                                                <b>No of papers requested:</b> {data['session_metrics']['no_of_papers_requested']}<br/>
                                                <b>No of papers saved:</b> {data['session_metrics']['no_of_papers_saved']}<br/>
                                                <b>No of papers downloaded:</b> {data['session_metrics']['no_of_papers_downloaded']}<br/>
                                                </span>
                                                }
                                                </span>}>
                                            <HelpOutlineIcon/>
                                        </CustomTooltip>
                                        </span>
                                    </Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                align={'flex-end'}
                                headerName={t('Requested papers')}
                                textAlign={'end'}
                                headerTitle={data['no_of_papers_requested']}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                align={'flex-end'}
                                textAlign={'end'}
                                headerName={t('Found papers')}
                                headerTitle={data['no_of_results_found']}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                align={'flex-end'}
                                textAlign={'end'}
                                headerName={t('Downloaded papers')}
                                headerTitle={data['total_paper_count']}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Source provider')}
                                headerContent={<Grid container spacing={0.5}>
                                    {data['source_providers']?.map(provider => {
                                        return <Grid item><LabeledChip label={t(getSource(provider.source_provider))}
                                                                       backgroundColor={getColor(provider.source_provider)}/></Grid>
                                    })}</Grid>}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Created at')}
                                headerTitle={format(parseISO(data.created_at), 'Pp')}
                            />
                            <CardHeaderColumn
                                spacing={1.6}
                                headerName={t('Created by')}
                                headerContent={<Grid container spacing={0.5}>
                                    <Grid item><ExecutedUser user={data['executed_user']}/></Grid>
                                </Grid>}
                            />
                            <Grid item container spacing={0.5} justifyContent={"flex-end"} alignItems={'center'} xs={1.6}
                                  sx={{height: '100%'}}>
                               <CustomTooltip title={'View Status'}>
                                   <IconButton onClick={handleView}>
                                       <InfoOutlinedIcon/>
                                   </IconButton>
                               </CustomTooltip>
                                <CustomTooltip title={t('inbox.searchPhrases.viewPapers')}>
                                    <IconButton onClick={() => handleViewPapers(data.download_session_id)}>
                                        <OpenInNewIcon/>
                                    </IconButton>
                                </CustomTooltip>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                </Accordion>
                {paperViewingOpen &&
                    <ViewPaperDrawer open={paperViewing} handleClose={handleViewPaperClose} height={'30vh'}/>}
            </CardRoot>
        );
    }
;

export default SearchSessionCard;