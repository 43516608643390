import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import CustomDrawer from 'components/elements/CustomDrawer';

import SummaryList from './summary-list';

const ViewPaperDrawer = ({open, handleClose}) => {
    const {t} = useTranslation();
    const selectedVertical = useSelector((state) => state.app.vertical);

    return (
        <React.Fragment>
            <CustomDrawer open={open} onclose={handleClose}>
                <Box sx={{width: '50vw', padding: 4}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={11} container spacing={2} sx={{paddingBottom: '32px !important'}}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('Papar download summary')}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={2} sx={{
                            paddingTop: '0 !important',
                        }}>
                           <SummaryList selectedVertical={selectedVertical}
                                        sessionId={open['download_session_id']}
                                        sort={{}}
                                        height={'calc(100vh - 144px)'}/>
                        </Grid>
                    </Grid>
                </Box>
            </CustomDrawer>
        </React.Fragment>
    );
};

export default ViewPaperDrawer;