import React from 'react';
import styled from '@mui/system/styled';
import {useTranslation} from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import MuiTableCell from '@mui/material/TableCell';

import CustomTooltip from 'components/elements/CustomTooltip';
import LabeledChip from 'components/elements/LabeledChip';

const TableCell = styled(MuiTableCell)(({eligible = false}) => ({
    fontSize: '0.875rem',
    background: '#ffffff',
    ...(eligible && {backgroundColor: 'rgba(247,108,108,0.4)'})
}));


const SummaryRow = ({
                      data, index, refetch = () => {
        }
                  }) => {
        const colors = ['#efefcb', '#c8edfd', '#daf7ca']
        const {t} = useTranslation();


        const getLabel= (type) => {
            switch (type) {
                case 'SUCCESS':
                    return <LabeledChip label={`${t('Success')}`}
                                        backgroundColor={'#daf7ca'}/>;
                case 'FAILURE':
                    return <LabeledChip label={`${t('Failure')}`}
                                        cursor={'pointer'}
                                        backgroundColor={'#f76c6c'}/>;
                default:
                    return type
            }
        }

        const paperStatusTooltip = () => {
            if (data['download_status'] === 'FAILURE') {
              return data['display_download_error']
            } else {
                return ''
            }
        }

        return (
            <>
                <TableCell sx={{position: 'sticky', left: 0, zIndex: 1}}>
                    <span style={{cursor:'pointer'}}>{data['paper_name']}</span>
                </TableCell>
                <TableCell width={0.6} align={'left'}>
                    <CustomTooltip
                        title={`${paperStatusTooltip()}`}>  <span style={{cursor:'pointer !important'}}>{getLabel(data['download_status'])}</span>
                    </CustomTooltip>
                </TableCell>
            </>
        );
    }
;

export default SummaryRow;